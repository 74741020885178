import { useState, useRef } from 'react';
import { TextField, TextFieldProps, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { DatePicker, LocalizationProvider, plPL } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Control, useController } from "react-hook-form";

dayjs.extend(customParseFormat);

interface Props {
    name: string;
    control: Control;
    label: string;
    disabled?: boolean;
}

export default function AppDateInput({ name, control, label, disabled }: Props) {
    const [openDialog, setOpenDialog] = useState(false);
    const [tempValue, setTempValue] = useState<Dayjs | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const {
        field: { onChange, value, onBlur },
        fieldState: { error }
    } = useController({
        name,
        control,
        defaultValue: '',
        rules: {
            required: "Wypełnij to pole",
            validate: value => {
                if (value === '' || value === null || value === undefined) return "Wypełnij to pole";
                const selectedDate = dayjs(value, "DD.MM.YYYY", true);
                if (!selectedDate.isValid()) return "Nieprawidłowa data";
                if (dayjs().isBefore(selectedDate, 'day')) return "Data nie może być większa niż obecny dzień";
                return true;
            }
        }
    });

    const handleDateChange = (newValue: Dayjs | null) => {
        // Upewnij się, że wartość jest pełną datą
        if (newValue && newValue.isValid()) {
            if (dayjs().diff(newValue, 'year') < 18) {
                setTempValue(newValue);
                setOpenDialog(true);
            } else {
                onChange(newValue.format("DD.MM.YYYY"));
            }
        } else {
            onChange('');
        }
    };


    const handleDialogClose = (accept: boolean) => {
        if (accept && tempValue) {
            onChange(tempValue.format("DD.MM.YYYY"));
        } else {
            onChange('');
        }
        setTempValue(null);
        setOpenDialog(false);
    };

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pl"
            localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <DatePicker
                label={label}
                disabled={disabled}
                value={value ? dayjs(value, "DD.MM.YYYY") : null}
                onChange={handleDateChange}
                inputFormat="DD.MM.YYYY"
                mask="__.__.____"
                maxDate={dayjs()}
                PopperProps={{
                    anchorEl: inputRef.current,
                    placement: 'bottom-start',
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 10], // Adjust the offset as needed
                            },
                        },
                    ],
                }}
                renderInput={(params: TextFieldProps) => (
                    <TextField
                        {...params}
                        fullWidth
                        error={!!error}
                        helperText={error ? error.message : ''}
                        inputRef={inputRef}
                        onBlur={(event) => {
                            const rawValue = event.target.value;
                            const parsedDate = dayjs(rawValue, "DD.MM.YYYY", true);
                
                            if (parsedDate.isValid()) {
                                handleDateChange(parsedDate);
                            } else {
                                onChange(''); // Resetuje wartość jeśli data jest nieprawidłowa
                            }
                            onBlur();
                        }}
                    />
                )}
            />
            <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
                <DialogTitle>Potwierdź wybór daty</DialogTitle>
                <DialogContent>
                    Wybrana data wskazuje, że osoba ma mniej niż 18 lat. Czy na pewno chcesz kontynuować?
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <Button variant='outlined' color='error' onClick={() => handleDialogClose(false)}>Odrzuć</Button>
                    <Button variant='contained' color='success' onClick={() => handleDialogClose(true)}>Zatwierdź</Button>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    );
}