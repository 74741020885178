import { Avatar, Button, Card, CardActions, CardContent, Typography, CardHeader, Divider, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Dialog, DialogContent } from "@mui/material";
import { Link } from "react-router-dom";
import { Order } from "../../../../app/models/order";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { currencyFormat, formatToLocalDate, formatToLocalTime, getOrderStatusColor, getOrderStatusTitle, getPolishLiteral } from "../../../../app/util/util";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import agent from "../../../../app/api/agent";
import { keyframes } from '@emotion/react';
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import AdminOrderScanUploadForm from "../AdminOrderScanUploadForm";

interface Props {
    order: Order;
    handleCardSelectOrder?: (handledOrder: Order) => void;
    handleCardSelectAddInvoice?: (handledOrder: Order) => void;
}

export default function AdminOrderCard({ order, handleCardSelectOrder, handleCardSelectAddInvoice }: Props) {
    const benefitients = order.benefitients ? order.benefitients.split(';').map(item => {
        const [lastName, firstName, pesel, birthPlace, birthDate, certId] = item.split('~');
        return {
            lastName, firstName, pesel, birthPlace, birthDate,
            certId: certId !== undefined ? certId : ''
        };
    }) : [];
    const [isGeneratingCertificate, setIsGeneratingCertificate] = useState(false);
    // const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(false);
    const [open, setOpen] = useState(false);

    function getStatusIcon(currentState: number) {
        switch (currentState) {
            case 0:
                return <HourglassTopIcon />;
            case 1:
                return <HourglassTopIcon />;
            case 2:
                return <DoneIcon />;
            case 3:
                return <DoneIcon />;
            case 4:
                return <HelpOutlineIcon />;
            case 5:
                return <CancelIcon />;
            default:
                return <ErrorIcon />;
        }
    }

    const downloadBlob = (blob: Blob, fileName: string) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    async function handleGenerateCertificates(orderId: number) {
        setIsGeneratingCertificate(true); // Rozpoczęcie generowania certyfikatu
        try {
            const response = await agent.GenerateCertificate.generateCertificate(orderId);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            downloadBlob(blob, `Certyfikaty_${orderId}.pdf`);
        } catch (error) {
            console.error("Błąd podczas generowania certyfikatów", error);
        }
        setIsGeneratingCertificate(false); // Zakończenie generowania certyfikatu
    }

    // async function handleGenerateInvoice() {
    //     setIsGeneratingInvoice(true);
    //     try {
    //         const response = await agent.BizInApi.apiTestConnection();
    //         console.log(response);
    //         toast(response);
    //     } catch (error) {
    //         console.error("Błąd podczas generowania faktury", error);
    //     }
    //     setIsGeneratingInvoice(false);
    // }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const colorChange = keyframes`
        0% {
            background-color: red;
        }
        100% {
            background-color: orange;
        }
    `;

    return (
        <Card
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: getOrderStatusColor(order.orderState) }} title={getOrderStatusTitle(order.orderState)}> {getStatusIcon(order.orderState)}</Avatar>
                }
                title={order.userDisplayName && order.userDisplayName.trim() ? order.userDisplayName : order.userEmail}
                titleTypographyProps={{
                    sx: { fontWeight: 'bold', color: 'secondary' }
                }}
                subheader={`${formatToLocalDate(order.orderDateTime.toString())} ${formatToLocalTime(order.orderDateTime.toString())}`}
            />
            <Divider />
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">
                    <strong>{order.customeClientServiceName}</strong>
                </Typography>
                <Typography sx={{ fontSize: 12, mb: 2 }} gutterBottom>
                    nazwa wskazana przez Klienta
                </Typography>
                <Typography variant="subtitle2">
                    <strong>#{order.publicId}</strong>
                </Typography>
                <Typography sx={{ fontSize: 12, mb: 2 }} gutterBottom>
                    numer zamówienia
                </Typography>
                <Typography variant="subtitle2" sx={{ color: getOrderStatusColor(order.orderState) }}>
                    {getOrderStatusTitle(order.orderState)}
                </Typography>
                <Typography sx={{ fontSize: 12, mb: 2 }} gutterBottom>
                    status zamówienia
                </Typography>
                <Typography>
                    {currencyFormat(order.totalDiscountedPrice)} ( {currencyFormat(order.unitDiscountedPrice)} x {getPolishLiteral(order.totalItem)} )
                </Typography>
                <Typography sx={{ fontSize: 12, mb: 2 }} gutterBottom>
                    wartość zamówienia
                </Typography>

                {order.clientComment &&
                    <>
                        <Typography variant="subtitle2">
                            <strong>{order.clientComment}</strong>
                        </Typography>
                        <Typography sx={{ fontSize: 12, mb: 2 }} gutterBottom>
                            komentarz/uwagi Klienta
                        </Typography>
                    </>
                }

                <Typography>
                    {order.userPhone}
                </Typography>
                <Typography sx={{ fontSize: 12, mb: 2 }} gutterBottom>
                    nr telefonu podany przez Klienta
                </Typography>
            </CardContent>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>Uczestnicy</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        {benefitients.map((benefitient, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={`${benefitient.firstName} ${benefitient.lastName}`} secondary={`Pesel: ${benefitient.pesel}, Miejsce urodzenia: ${benefitient.birthPlace}, Data urodzenia: ${benefitient.birthDate}, Certyfikat: ${benefitient.certId}`} />
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
            <CardActions
                disableSpacing
                sx={{
                    mt: "auto",
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: 1
                }}
            >
                <Button
                    sx={{
                        mr: 1, width: '100px', height: '40px', ...(order.orderState == 4 && {
                            animation: `${colorChange} 2s infinite`,
                            // Remove default button color when using custom background color animation
                            bgcolor: 'transparent',
                            // Adjust text color to be visible on both orange and red
                            color: 'white',
                        })
                    }}
                    variant='outlined'
                    component={Link} to={`/admin/orders/${order.publicId}`}
                    size="small"
                    title='Więcej'
                >Więcej</Button>
                <Button
                    sx={{ mr: 1, width: '100px', height: '40px' }}
                    variant='outlined'
                    onClick={() => handleCardSelectOrder!(order)}
                    size="small"
                    title='Edycja'
                    color='warning'
                >Edycja</Button>
                <Button
                    sx={{ width: '100px', height: '40px' }}
                    variant='outlined'
                    onClick={handleOpen}
                    size="small"
                    title='Załącz'
                    color='info'
                >
                    Załącz
                </Button>
                {order.orderState == 2 || order.orderState == 3 ? (
                    <LoadingButton
                        loading={isGeneratingCertificate}
                        sx={{ mr: 1, height: '40px' }}
                        variant='outlined'
                        onClick={() => handleGenerateCertificates(order.publicId)}
                        size="small"
                        title='Pobierz Certyfikaty'
                        color='success'
                    >Pobierz Certyfikaty</LoadingButton>

                ) : ''}
                {order.orderState == 2 &&
                    <Button
                        sx={{ mr: 1, height: '40px' }}
                        variant='outlined'
                        onClick={() => handleCardSelectAddInvoice!(order)}
                        size="small"
                        title='Wystaw FV'
                        color='warning'
                    >Wystaw FV</Button>
                }

                <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                        <AdminOrderScanUploadForm order={order} cancelUpload={handleClose} />
                    </DialogContent>
                </Dialog>
            </CardActions>
        </Card >
    )
}